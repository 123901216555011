import { Suspense } from "react";

import { LinearProgress } from "@mui/material";

const Loader = () => {
  return (
    <LinearProgress
      sx={{
        height: "6px",
        width: "100%",
        borderRadius: "0px",
      }}
      color="primary"
    />
  );
};

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
