import { Navigate, useRoutes } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import UserRoutes from "./UserRoutes";

const AppRoutes = () => {
  return useRoutes([
    AuthRoutes(),
    UserRoutes(),
    {
      path: "/404",
      element: <p>404 Page</p>,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
};

export default AppRoutes;
