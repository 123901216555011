import {
  CLEAR_ALERT,
  CLEAR_BANNER,
  CLEAR_LOADING,
  CLEAR_USER,
  SET_ALERT,
  SET_BANNER,
  SET_LOADING,
  SET_MODE,
  SET_USER,
} from "./Action";

const Reducer = (currentState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return {
        ...currentState,
        showAlert: true,
        alertText: payload.alertText,
        alertType: payload.alertType,
      };
    case CLEAR_ALERT:
      return {
        ...currentState,
        showAlert: false,
        alertText: "",
        alertType: "success",
      };
    case SET_LOADING:
      return {
        ...currentState,
        loaderCount: currentState.loaderCount + 1,
      };
    case CLEAR_LOADING:
      return {
        ...currentState,
        loaderCount: currentState.loaderCount - 1,
      };
    case SET_USER:
      return {
        ...currentState,
        user: payload,
      };
    case CLEAR_USER:
      return {
        ...currentState,
        user: null,
      };
    case SET_MODE:
      return {
        ...currentState,
        mode: payload,
      };
    case SET_BANNER:
      return {
        ...currentState,
        showBanner: true,
        bannerText: payload.bannerText,
        bannerType: payload.bannerType,
      };
    case CLEAR_BANNER:
      return {
        ...currentState,
        showBanner: false,
        bannerText: "",
        bannerType: "info",
      };
    default:
      return currentState;
  }
};

export default Reducer;
