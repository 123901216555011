import axios from "axios";
import { baseURL } from "../utils/constants";
import {
  CLEAR_LOADING,
  CLEAR_USER,
  SET_ALERT,
  SET_LOADING,
  SET_USER,
} from "../context/Action";

export const handleSignIn = async (dispatch, { email, password }) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await axios.post(`${baseURL}/users/login`, {
      email,
      password,
    });
    const token = response.data?.data?.token;
    if (token) {
      axios.defaults.headers.common.token = token;
      sessionStorage.setItem("authUser", JSON.stringify(response.data?.data));
      dispatch({ type: SET_USER, payload: response.data?.data });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText:
            "Invalid email or password. Please enter valid credentials.",
          alertType: "error",
        },
      });
    }
  } catch (error) {
    console.error(error);
    if (error.response) {
      // If the server responds with an error, check the error status and display appropriate messages
      if (error.response.status === 400) {
        dispatch({
          type: SET_ALERT,
          payload: {
            alertText: "Invalid password. Please enter a valid password.",
            alertType: "error",
          },
        });
      } else if (error.response.status === 401) {
        dispatch({
          type: SET_ALERT,
          payload: {
            alertText:
              "Invalid email format. Please enter a valid email address.",
            alertType: "error",
          },
        });
      } else {
        dispatch({
          type: SET_ALERT,
          payload: {
            alertText: "An error occurred while logging in.",
            alertType: "error",
          },
        });
      }
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText: "An error occurred while logging in.",
          alertType: "error",
        },
      });
    }
  } finally {
    dispatch({ type: CLEAR_LOADING });
  }
};

export const handleSignOut = (dispatch) => {
  sessionStorage.removeItem("authUser");
  axios.defaults.headers.common.token = null;
  dispatch({ type: CLEAR_USER });
  dispatch({
    type: SET_ALERT,
    payload: {
      alertText: "You have been successfully logged out.",
      alertType: "info",
    },
  });
};

export const handleSignUp = async (
  dispatch,
  { firstName, lastName, organization, mobile, email, password }
) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await axios.post(`${baseURL}/users/create`, {
      firstname: firstName,
      lastname: lastName,
      email,
      mobile,
      password,
      organization,
      role: "orgAdmin",
    });
    const token = response.data?.data?.token;
    if (token) {
      axios.defaults.headers.common.token = token;
      sessionStorage.setItem("authUser", JSON.stringify(response.data?.data));
      dispatch({ type: SET_USER, payload: response.data?.data });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText:
            "Invalid email or password. Please enter valid credentials.",
          alertType: "error",
        },
      });
    }
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_ALERT,
      payload: {
        alertText: "An error occurred while signing in.",
        alertType: "error",
      },
    });
  } finally {
    dispatch({ type: CLEAR_LOADING });
  }
};

export const handleForgotPassword = async (dispatch, { email }) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await axios.put(`${baseURL}/users/forgot-password`, {
      email,
    });
    if (response.data?.status === 200) {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText: "Password reset link has been sent to your email.",
          alertType: "info",
        },
      });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText: "An error occurred while sending the password reset link.",
          alertType: "error",
        },
      });
    }
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_ALERT,
      payload: {
        alertText: "An error occurred while sending the password reset link.",
        alertType: "error",
      },
    });
  } finally {
    dispatch({ type: CLEAR_LOADING });
  }
};

export const handleChangePassword = async (dispatch, { email, password }) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response = await axios.put(`${baseURL}/users/change-password`, {
      email,
      password,
    });
    if (response.data?.status === 200) {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText: "Password has been successfully changed.",
          alertType: "info",
        },
      });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          alertText: "An error occurred while changing the password.",
          alertType: "error",
        },
      });
    }
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_ALERT,
      payload: {
        alertText: "An error occurred while changing the password.",
        alertType: "error",
      },
    });
  } finally {
    dispatch({ type: CLEAR_LOADING });
  }
};
