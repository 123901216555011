import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useAppContext } from "../../context/AppContext";
import { SET_MODE } from "../../context/Action";
import { useEffect } from "react";
import { useState } from "react";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";

export default function MenuContent() {
  const location = useLocation();
  const selectedLocation = location.pathname;
  const { state, dispatch } = useAppContext();
  const xs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const toggleColorMode = React.useCallback(() => {
    const newMode = state.mode === "dark" ? "light" : "dark";
    dispatch({ type: SET_MODE, payload: newMode });

    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  }, [state.mode, dispatch]);
  const mainListItems = [
    { text: "Home", icon: <HomeRoundedIcon />, link: "/dashboard" },
    { text: "Projects", icon: <AccountTreeIcon />, link: "/projects" },
    { text: "Tests", icon: <DataThresholdingIcon />, link: "/tests" },
    { text: "Users", icon: <PeopleRoundedIcon />, link: "/users" },
  ];

  const [secondaryListItems, setSecondaryListItems] = useState([]);

  useEffect(() => {
    if (xs) {
      setSecondaryListItems([
        { text: "AWS Setup", icon: <BuildIcon />, link: "/aws-setup" },
        {
          text: "Subscriptions",
          icon: <CreditCardIcon />,
          link: "/subscriptions",
        },
        {
          text: "Settings",
          icon: <Settings />,
          link: "/settings",
        },
        {
          text: "Toggle Color Mode",
          icon:
            state.mode === "dark" ? (
              <WbSunnyRoundedIcon />
            ) : (
              <ModeNightRoundedIcon />
            ),
          onClick: toggleColorMode,
        },
      ]);
    } else {
      setSecondaryListItems([
        { text: "AWS Setup", icon: <BuildIcon />, link: "/aws-setup" },
        {
          text: "Subscriptions",
          icon: <CreditCardIcon />,
          link: "/subscriptions",
        },
      ]);
    }
  }, [xs, state.mode, toggleColorMode]);

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{ display: "block", pt: 0.5, pb: 0.2, pl: 0 }}
          >
            <ListItemButton
              selected={selectedLocation === item.link}
              component="a"
              href={item.link}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{ display: "block", pt: 0.5, pb: 0.2, pl: 0 }}
          >
            <ListItemButton
              component="a"
              href={item.link}
              selected={selectedLocation === item.link}
              onClick={item.onClick}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
