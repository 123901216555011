import { lazy, useEffect } from "react";
import Loadable from "../components/Loadable";
import SecondaryTemplate from "../Templates/Secondary/index";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const SignUpLoader = Loadable(lazy(() => import("../views/SignUp")));
const SignInLoader = Loadable(lazy(() => import("../views/SignIn")));

const UnProtectedRoutes = ({ children }) => {
  const { state } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (state.user) {
      navigate("/", { replace: true });
    }
  }, [state.user, navigate]);
  return children;
};

const AuthRoutes = () => {
  return {
    path: "/auth",
    element: (
      <UnProtectedRoutes>
        <SecondaryTemplate />
      </UnProtectedRoutes>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignInLoader />,
      },
      {
        path: "sign-up",
        element: <SignUpLoader />,
      },
    ],
  };
};

export default AuthRoutes;
