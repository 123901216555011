import { createContext, useContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  loaderCount: 0,
  showAlert: false,
  alertText: "",
  alertType: "info",
  user: sessionStorage.getItem("authUser")
    ? JSON.parse(sessionStorage.getItem("authUser"))
    : null,
  mode: "light",
  showBanner: false,
  bannerText: "",
  bannerType: "info",
};

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
