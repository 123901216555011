import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppContext } from "../context/AppContext";

const LoadingModal = () => {
  const modalStyle = {
    zIndex: 9999,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const { state } = useAppContext();

  return (
    <Backdrop open={state.loaderCount > 0} style={modalStyle}>
      <CircularProgress size={55} thickness={5} />
    </Backdrop>
  );
};

export default LoadingModal;
