import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Logo from "../../components/Logo";
import { useAppContext } from "../../context/AppContext";
import MenuContent from "./MenuContent";
import OptionsMenu from "./OptionsMenu";

const drawerWidth = 260;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

export default function SideMenu() {
  const { state } = useAppContext();
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <Box
        sx={{
          mx: 1.5,
          my: 1.75,
        }}
        component="a"
        href="/"
        display={"flex"}
        justifyContent={"center"}
      >
        <Logo width={125} />
      </Box>
      <Divider />
      <MenuContent />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: "center",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
        justifyContent={"space-between"}
      >
        <Stack direction="row" spacing={2}>
          <Avatar
            sizes="small"
            alt={state?.user?.firstname}
            sx={{ width: 36, height: 36 }}
          >
            {state?.user?.firstname?.charAt(0)}
          </Avatar>
          <Box maxWidth={"135px"}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                lineHeight: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {state?.user?.firstname} {state?.user?.lastname}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "text.secondary",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {state?.user?.email}
            </Typography>
          </Box>
        </Stack>
        <OptionsMenu />
      </Stack>
    </Drawer>
  );
}
