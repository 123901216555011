import { Alert, IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "../context/AppContext";
import { CLEAR_ALERT, SET_ALERT } from "../context/Action";

const AlertSection = () => {
  const [snackPack, setSnackPack] = useState([]);
  const { state, dispatch } = useAppContext();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: CLEAR_ALERT });
  };

  useEffect(() => {
    if (snackPack.length && state.alertText.trim() === "") {
      setSnackPack((prev) => prev.slice(1));
      dispatch({
        type: SET_ALERT,
        payload: {},
      });
    } else if (
      snackPack.length &&
      state.alertText.trim() !== "" &&
      state.showAlert
    ) {
      dispatch({ type: CLEAR_ALERT });
    }
  }, [snackPack, state.showAlert, state.alertText, dispatch]);

  return (
    <Snackbar
      open={state.showAlert}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={state.alertType || "success"}
        sx={{ width: "100%" }}
      >
        {state.alertText}
      </Alert>
    </Snackbar>
  );
};

export default AlertSection;
