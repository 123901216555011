import React from "react";
import logoPath from "../assets/logo.png";


const Logo = ({ width = 125, ...props }) => {
  return (
    <img
      src={logoPath} 
      alt="Baseline11"
      width={width}
      {...props}
    />
  );
};

export default Logo;
