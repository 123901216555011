import * as React from "react";
import { createTheme, ThemeProvider, alpha } from "@mui/material/styles";

import Box from "@mui/material/Box";
import getDashboardTheme from "../../theme/getDashboardTheme";
import { useAppContext } from "../../context/AppContext";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import { Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";
import { SET_MODE } from "../../context/Action";
import { Alert } from "@mui/material";
import AppNavbar from "./AppNavbar";

function MainTemplate() {
  const { state, dispatch } = useAppContext();
  const dashboardTheme = createTheme(getDashboardTheme(state.mode));

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      dispatch({ type: SET_MODE, payload: savedMode });
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      dispatch({
        type: SET_MODE,
        payload: systemPrefersDark ? "dark" : "light",
      });
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={dashboardTheme}>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: "1 1", overflow: "auto" }}>
          <ThemeProvider theme={dashboardTheme}>
            <CssBaseline enableColorScheme />
            <Box sx={{ display: "flex" }}>
              <SideMenu />
              <AppNavbar />
              {/* Main content */}
              <Box
                component="main"
                sx={(theme) => ({
                  flexGrow: 1,
                  backgroundColor: alpha(theme.palette.background.default, 1),
                  overflow: "auto",
                })}
              >
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    mt: { xs: 8, md: 0 },
                  }}
                >
                  {state.showBanner && (
                    <Alert
                      icon={false}
                      sx={{
                        width: "100%",
                        justifyContent: "center",
                        borderRadius: 0,
                      }}
                      severity={state.bannerType}
                    >
                      {state.bannerText}
                    </Alert>
                  )}
                  <Outlet />
                </Stack>
              </Box>
            </Box>
          </ThemeProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainTemplate;
