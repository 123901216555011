import * as React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import getDashboardTheme from "../../theme/getDashboardTheme";
import { useAppContext } from "../../context/AppContext";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { SET_MODE } from "../../context/Action";
import { Stack } from "@mui/material";

const MainContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  component: "main",
  display: "flex",
  justifyContent: "center",
  minHeight: "100vh",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

function SecondaryTemplate() {
  const { state, dispatch } = useAppContext();
  const dashboardTheme = createTheme(getDashboardTheme(state.mode));

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      dispatch({ type: SET_MODE, payload: savedMode });
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      dispatch({
        type: SET_MODE,
        payload: systemPrefersDark ? "dark" : "light",
      });
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={dashboardTheme}>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: "1 1", overflow: "auto" }}>
          <ThemeProvider theme={dashboardTheme}>
            <CssBaseline enableColorScheme />
            <MainContainer direction={"column"}>
              <Outlet />
            </MainContainer>
          </ThemeProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SecondaryTemplate;
